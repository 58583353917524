import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { referralSchema } from "helpers/schema";
import FormInput from "components/common/base/FormInput";
import { Button } from "components/common/base/button";

const ReferralForm = ({ onSubmit, isSubmitting }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(referralSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <FormInput
              {...field}
              type="number"
              placeholder="Referred By"
              error={errors?.phone && errors.phone.message}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="referralCode"
          control={control}
          render={({ field }) => (
            <FormInput
              {...field}
              type="text"
              placeholder="Referral Code"
              error={errors?.referralCode && errors.referralCode.message}
            />
          )}
        />
      </div>
      <Button
        type="submit"
        value={isSubmitting ? "Submitting..." : "Submit"}
        variant="primary"
        height={50}
        disabled={isSubmitting}
      />
    </form>
  );
};

export default ReferralForm;
